import { modifySeoUrl } from "../utils/helpers/combined-header-options-parser";

const VIEW_TYPE = {
    GRID: "GRID",
    LIST: "LIST"
};

export const newCarsBodyTypeLink = [
    {
        title: "Sedan",
        url: modifySeoUrl("new-cars/search-by/sedan-cars")
    },
    {
        title: "SUV",
        url: modifySeoUrl("new-cars/search-by/suv-cars/")
    },
    {
        title: "Hatchback",
        url: modifySeoUrl("new-cars/search-by/hatchback-cars/")
    },
    {
        title: "MUV",
        url: modifySeoUrl("new-cars/search-by/muv-cars/")
    }
];

export const newCarsBrandsLink = [
    {
        title: "Tata",
        url: modifySeoUrl("new-cars/tata/"),
        imageUrl: "https://fastly-production.24c.in/cars24/brand/fdafe75f-62ea-4cb9-8fca-3d57863a1e75TATAnew.png"
    },
    {
        title: "Maruti Suzuki",
        url: modifySeoUrl("new-cars/maruti-suzuki/"),
        imageUrl: "https://fastly-production.24c.in/cars24/brand/12330d28-b357-4010-8d0c-6141a3f82e48MARUTISUZUKI17.png"
    },
    {
        title: "Kia",
        url: modifySeoUrl("new-cars/kia/"),
        imageUrl:
      "https://fastly-production.24c.in/cars24/brand/90abec5b-37fd-4039-9732-1c3c0ed6a888KIAnew.png"
    },
    {
        title: "Toyota",
        url: modifySeoUrl("new-cars/toyota/"),
        imageUrl: "https://fastly-production.24c.in/cars24/brand/d52238b9-b9b9-4cff-b261-5d036f03c745TOYOTAnew.png"
    },
    {
        title: "MG",
        url: modifySeoUrl("new-cars/mg/"),
        imageUrl: "https://fastly-production.24c.in/cars24/brand/mg_1660106276.png"
    },
    {
        title: "Honda",
        url: modifySeoUrl("new-cars/honda/"),
        imageUrl: "https://fastly-production.24c.in/cars24/brand/b391b164-ebad-421a-9c8d-164c2adfb58dHONDAnew.png"
    }
];

export const newCarsPopularLinks = [
    {
        title: "Hyundai Creta",
        url: modifySeoUrl("new-cars/hyundai/creta/")
    },
    {
        title: "Mahindra Thar",
        url: modifySeoUrl("new-cars/mahindra/thar/")
    },
    {
        title: "Tata Nexon",
        url: modifySeoUrl("new-cars/tata/nexon/")
    },
    {
        title: "Tata Punch",
        url: modifySeoUrl("new-cars/tata/punch/")
    },
    {
        title: "Maruti Swift",
        url: modifySeoUrl("new-cars/maruti-suzuki/swift/")
    },
    {
        title: "Kia Seltos",
        url: modifySeoUrl("new-cars/kia/seltos/")
    }
];

export const newCarsPriceLink = [
    {
        title: "Under 5 Lakhs",
        url: modifySeoUrl("new-cars/search-by/cars-under-5-lakhs/")
    },
    {
        title: "Under 10 Lakhs",
        url: modifySeoUrl("new-cars/search-by/cars-under-10-lakhs/")
    },
    {
        title: "Under 15 Lakhs",
        url: modifySeoUrl("new-cars/search-by/cars-under-15-lakhs/")
    },
    {
        title: "Under 20 Lakhs",
        url: modifySeoUrl("new-cars/search-by/cars-under-20-lakhs/")
    }
];

export const newCarLinks = [
    {
        heading: "Popular Brands",
        links: newCarsBrandsLink,
        viewType: VIEW_TYPE?.GRID,
        showLinkTitle: false
    },
    {
        heading: "Popular Cars",
        links: newCarsPopularLinks,
        viewType: VIEW_TYPE?.LIST
    },
    {
        heading: "Car By Body Type",
        links: newCarsBodyTypeLink,
        viewType: VIEW_TYPE?.LIST
    },
    {
        heading: "Cars By Price Range",
        links: newCarsPriceLink,
        viewType: VIEW_TYPE?.LIST
    }
];
